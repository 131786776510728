<template>
  <UiPopup
    :model-value="modelValue"
    :title="`Delete ${isImage ? 'image' : 'file'}?`"
    :description="`Are you sure you want to delete this ${isImage ? 'image' : 'file'} permanently?`"
    primary-button-text="Yes, delete"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="deleteAttachment()"
  />
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  type: string
}

const props = defineProps<Props>()

const isImage = computed(() => props.type === 'image')

const deleteAttachment = () => {
  emits('input')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
